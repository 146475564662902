import { IErrorMetadata } from "../interfaces/login-response-error.interface";

export class AuthError extends Error {
  isSuccess!: boolean;
  isFailure!: boolean;
  error!: IErrorMetadata;

  constructor(args?: any) {
    super();
    this.isSuccess = args.isSuccess;
    this.isFailure = args.isFailure;
    this.error = args.error;
    this.message = this.error.message;
  }
}
