import { APIDataHandler } from "@core/models/data.handler";
import { ILoginResponse, ILoginUserData } from "../interfaces/login-response.interface";

export class AuthModel implements APIDataHandler<AuthModel> {
  appKey!: string;
  is_da_doi_mat_khau!: boolean;
  is_da_xac_thuc_so_dien_thoai!: boolean;
  is_xac_thuc_email!: boolean;
  listmenu_active!: number[] | string[];
  result!: ILoginUserData;
  token!: string;
  token_eduzaa!: string;

  mapDataFromAPI(args: ILoginResponse) {
    this.appKey = args?.app_key;
    this.is_da_doi_mat_khau = args?.is_da_doi_mat_khau;
    this.is_da_xac_thuc_so_dien_thoai = args?.is_da_xac_thuc_so_dien_thoai;
    this.is_xac_thuc_email = args?.is_xac_thuc_email;
    this.listmenu_active = args?.listmenu_active;
    this.result = args?.result;
    this.token = args?.token;
    this.token_eduzaa = args?.token_eduzaa;

    return this;
  }
}
