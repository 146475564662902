import { LocalStorageConfigKeys } from '@config/app-storage/local-storage.config';
import { HELPER_LOGIN_FAILURE_LINK as LINK } from '@features/noi-quy-lao-dong/util/variable';

// Link hướng dẫn khi người dùng đăng nhập không được
export const HELPER_LOGIN_FAILURE_LINK = LINK.HUTECH_URL;

// Array storage key not remove when logout
export const LOCAL_STORAGE_KEY_KEEP_SAVE_WHEN_LOGOUT = [
  // key from bundle.js
  'themeSettings',
  LocalStorageConfigKeys.global.transloco.currentLanguage,
  LocalStorageConfigKeys.global.layouts.currentThemeMode,
  LocalStorageConfigKeys.global.layouts.themeSetting,
];
export const SESSION_STORAGE_KEY_KEEP_SAVE_WHEN_LOGOUT = [];
